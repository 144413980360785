// something to compute which puzzle it is
// we assume we release one puzzle a day

const firstReleaseDate = new Date('2024-12-07');

function getPuzzleNumber() {
    const today = new Date();

    // Get the local date by zeroing out the time component
    const localToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const localFirstReleaseDate = new Date(firstReleaseDate.getFullYear(), firstReleaseDate.getMonth(), firstReleaseDate.getDate());

    const diffTime = Math.abs(localToday.getTime() - localFirstReleaseDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export default getPuzzleNumber;
