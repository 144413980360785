import { Container } from '@mui/material';
import { useIsMobile } from './Device';

const MainContent = ({ children }) => {
  if (useIsMobile()) {
    return <Container
      maxWidth="sm" 
      sx={{ 
        mt: 3, 
        mb: 3,
        pb: '72px'
      }}
    >
      {children}
    </Container>
  } else {
    return <Container 
      maxWidth="md"
      sx={{
        pb: '72px'
      }}
    >
      {children}
    </Container>
  }
};

export default MainContent;
