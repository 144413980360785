import React from 'react';
import {
  DndContext,
  TouchSensor,
  MouseSensor,
  useSensors,
  useSensor,
  closestCorners,
} from '@dnd-kit/core';
import {
  SortableContext,
  arraySwap,
  rectSwappingStrategy,
} from '@dnd-kit/sortable';
import GridCard from './GridCard';
import { useIsMobile } from './Device';


const Grid = ({ items, setItems, game, dispatch }) => {
  const [activeId, setActiveId] = React.useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 10,
      },
    })
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    if (over.data.current?.isLocked) return;

    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arraySwap(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  // order items by group first, then keep current ordering otherwise (stable)
  const itemMap = new Map(items.map(item => [item.id, item]));
  const hasGroup = new Map();
  let renderOrder = []
  for (const [tag, group] of game.groups) {
    for (const id of group.ids) {
      renderOrder.push(itemMap.get(id))
      hasGroup.set(id, true)
    }
  }

  // if game is over, reorder remaining items into groups
  if (game.attemptsRemaining === 0) {
    // identify remaining groups
    const remainingGroups = new Set();
    for (const [tag, cardIds] of game.tags) {
      remainingGroups.add(tag);
    }
    for (const item of renderOrder) {
      remainingGroups.delete(game.cardTags.get(item.id));
    }
    
    // for each remaining group add all items to renderOrder
    for (const tag of remainingGroups) {
      for (const id of game.tags.get(tag)) {
        renderOrder.push(itemMap.get(id))
        hasGroup.set(id, true)
      }
    }
  } else {
    for (const item of items) {
      if (!renderOrder.includes(itemMap.get(item.id))) {
        renderOrder.push(itemMap.get(item.id))
        hasGroup.set(item.id, false)
      }
    }
  }

  const collisionDetectionStrategy = (args) => {
    const collisions = closestCorners(args);
    
    // Filter out any collisions with locked items
    return collisions.filter(collision => {
      const itemData = collision.data.current;
      return !itemData?.isLocked;
    });
  };
  console.log(itemMap)
  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={renderOrder.map((item) => item.id)} strategy={rectSwappingStrategy}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: useIsMobile() ? 'repeat(4, 1fr)' : 'repeat(4, 1fr)',
            maxWidth: useIsMobile() ? '800px' : '100%',
            rowGap: '2px',
            columnGap: '2px',
            margin: '0 auto',
          }}
        >
          {renderOrder.map((item) => (
            <GridCard
              key={item.id}
              id={item.id}
              item={item}
              game={game}
              dispatch={dispatch}
              isLocked={hasGroup.get(item.id) ?? true}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default Grid;
